<template>
    <div class="logo">
        <transition name="fade">
            <span
                key="0"
                v-if="isCollapse"
                class="logo_title is-bold "
                :class="{ 'is-text': !type, 'is-img': type }"
            >
                <template v-if="type">
                    <img :src="website.logo" width="40" height="40" />
                </template>
                <template v-else>
                    {{ website.logo }}
                </template>
            </span>
        </transition>
        <transition-group name="fade">
            <template v-if="!isCollapse">
                <span class="logo_title is-bold" key="1">
                    {{ website.title }}
                </span>
                <span class="logo_subtitle" key="2">
                    {{ website.author }}
                </span>
            </template>
        </transition-group>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "logo",
    data() {
        return {};
    },
    props: ["isCollapse"],
    created() {},
    computed: {
        ...mapGetters(["website"]),
        type() {
            return this.website.logo.indexOf("static") != -1;
        }
    },
    methods: {}
};
</script>
<style lang="scss" scoped>
.fade-leave-active {
    transition: opacity 0.1s;
}
.fade-enter-active {
    transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
.logo {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
    overflow: hidden;
    box-sizing: border-box;
    white-space: nowrap;
}
</style>
