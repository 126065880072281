<template>
    <el-container class="avue-contail" :class="navigationStyle">
        <el-aside v-if="menuType === 'aside'" class="aside_menu" width="unset">
            <Sidebar class="avue-sidebar" :sider-menu="concatMenu" />
        </el-aside>
        <el-container class="container_main">
            <el-header class="avue-tabs">
                <!-- 顶部导航栏 -->
                <Top
                    :menu-type="menuType"
                    :top-menu="concatMenu"
                    :theme-name="themeName"
                />
            </el-header>
            <el-main class="avue-main">
                <!-- 标签栏 -->
                <template v-if="showWelTagsAndBreadcrumbs">
                    <Tags v-if="isTags === 0" />
                    <Breadcrumb v-else />
                </template>
                <!-- 主体视图层 -->
                <div
                    class="avue-view-container"
                    :style="avueViewContainerStyle"
                >
                    <el-scrollbar>
                        <transition name="fade-transverse">
                            <keep-alive>
                                <router-view
                                    class="avue-view"
                                    v-loading="isLoading"
                                    v-if="$route.meta.keepAlive && isRouteAlive"
                                />
                            </keep-alive>
                        </transition>
                        <transition name="fade-transverse">
                            <router-view
                                class="avue-view"
                                v-loading="isLoading"
                                v-if="!$route.meta.keepAlive && isRouteAlive"
                            />
                        </transition>
                    </el-scrollbar>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>
<script>
import { mapGetters } from "vuex";
import Top from "./top";
import Sidebar from "./sidebar/index.vue";
import Tags from "./tags";
import Breadcrumb from "./breadcrumb";
import { validatenull } from "@/utils/validate";
import { initRoutes } from "@/utils/router";

export default {
    name: "Index",
    components: {
        Top,
        Sidebar,
        Tags,
        Breadcrumb
    },
    data() {
        return {
            defaultMenu: [],
            showWelTagsAndBreadcrumbs: true,
            isRouteAlive: true
        };
    },
    computed: {
        ...mapGetters([
            "isCollapse",
            "isTags",
            "isLoading",
            "themeName",
            "menu",
            "menuType"
        ]),
        navigationStyle: function() {
            return {
                topBlue:
                    this.menuType === "top" && this.themeName === "theme-one",
                topWhite:
                    this.menuType === "top" && this.themeName === "theme-two",
                topBlack:
                    this.menuType === "top" && this.themeName === "theme-three",
                topGray:
                    this.menuType === "top" && this.themeName === "theme-four",
                asideBlue:
                    this.menuType === "aside" && this.themeName === "theme-one",
                asideWhite:
                    this.menuType === "aside" && this.themeName === "theme-two",
                asideBlack:
                    this.menuType === "aside" &&
                    this.themeName === "theme-three",
                asideGray:
                    this.menuType === "aside" && this.themeName === "theme-four"
            };
        },
        concatMenu() {
            return this.defaultMenu.concat(this.showMenu);
        },
        showMenu() {
            return this.checkMenu(this.menu, "/");
        },
        avueViewContainerStyle() {
            let style = [];
            let height = this.showWelTagsAndBreadcrumbs
                ? "calc(100% - 60px)"
                : "100%";
            style.push(`height: ${height}`);
            return style.join(";");
        }
    },
    provide() {
        return {
            reLoad: this.reLoad
        };
    },
    methods: {
        reLoad() {
            //设置当前页为不缓存
            this.$route.meta.keepAlive = false;
            this.isRouteAlive = false;
            setTimeout(() => {
                this.$nextTick(() => {
                    this.isRouteAlive = true;
                });
            }, 200);
        },
        checkMenu(params, parentPath) {
            if (Array.isArray(params)) {
                params.forEach(item => {
                    if (item.path.indexOf("/") < 0) {
                        item.path = parentPath + item.path;
                    }
                    item.children = this.checkMenu(
                        item.children,
                        item.path + "/"
                    );
                });
            }
            return params;
        }
    },
    created() {
        if (validatenull(this.menu)) {
            this.$store.dispatch("GetMenu").then(() => {
                initRoutes(this.$router, this.menu);
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.avue-contail {
    height: 100vh;
    .avue-main {
        display: flex;
        flex-direction: column;
        .avue-view-container {
            flex: 1;
            position: relative;
            .avue-view {
                box-sizing: border-box;
            }
        }
    }
}
</style>
