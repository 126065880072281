<template>
    <div class="menu-wrapper">
        <template v-for="(item, index) in menu">
            <el-menu-item
                v-if="!item.children || item.children.length === 0"
                :index="filterPath(item.path, index)"
                @click="open(item)"
                :key="item.label"
            >
                <i :class="item.icons"></i>
                <span slot="title">{{ item.label }}</span>
            </el-menu-item>
            <el-submenu
                v-else
                :index="filterPath(item.label, index)"
                :key="item.name"
            >
                <template slot="title">
                    <i :class="item.icons"></i>
                    <span slot="title">{{ item.label }}</span>
                </template>
                <template v-if="item.children && item.children.length > 0">
                    <template v-for="(child, cindex) in item.children">
                        <el-menu-item
                            :class="{
                                'siderbar-active': nowTagValue == child.path
                            }"
                            :index="filterPath(child.path, cindex)"
                            @click="open(child)"
                            v-if="!child.children || child.children.length == 0"
                            :key="cindex"
                        >
                            <!--<i :class="child.icon"></i>-->
                            <span slot="title">{{ child.label }}</span>
                        </el-menu-item>
                        <sidebar-item
                            v-else
                            :menu="[child]"
                            :key="cindex"
                            :isCollapse="isCollapse"
                        ></sidebar-item>
                    </template>
                </template>
            </el-submenu>
        </template>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { resolveUrlPath, setUrlPath } from "@/utils/utils";

export default {
    name: "SidebarItem",
    props: {
        menu: {
            type: Array
        },
        isCollapse: {
            type: Boolean
        }
    },
    computed: {
        ...mapGetters(["themeName", "menuType"]),
        nowTagValue: function() {
            return setUrlPath(this.$route);
        }
    },
    methods: {
        filterPath(path, index) {
            return path == null ? index + "" : path;
        },
        open(item) {
            this.$router.push({
                path: resolveUrlPath(item.path, item.label),
                query: item.query
            });
        }
    }
};
</script>
<style lang="scss" scoped></style>
