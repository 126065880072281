export default {
    title: "彩票工具系统",
    logo: "彩",
    author: "Lix",
    whiteList: ["/login", "/401", "/404", "/lock", "/reset"],
    tokenTime: 6000,
    info: {
        title: "彩票工具系统",
        list: [
            "彩票工具系统是集成记录、分析及邮件提醒等多功能的平台",
            "是一个基于Laravel、Vue2.0开发的前后分离的平台",
            "当前版本：v1.0.0"
        ]
    },
    wel: {
        title: "彩票工具系统",
        list: [
            "彩票工具系统是集成记录、分析及邮件提醒等多功能的平台",
            "是一个基于Laravel、Vue2.0开发的前后分离的平台",
            "当前版本：v1.0.0"
        ]
    },
    iconList: [
        "el-icon-custom iconfont icon-cp-home",
        "el-icon-s-home",
        "el-icon-s-tools",
        "el-icon-setting",
        "el-icon-user-solid",
        "el-icon-user",
        "el-icon-phone",
        "el-icon-phone-outline",
        "el-icon-star-on",
        "el-icon-star-off",
        "el-icon-s-goods",
        "el-icon-goods",
        "el-icon-s-help",
        "el-icon-help",
        "el-icon-message-solid",
        "el-icon-bell",
        "el-icon-s-cooperation",
        "el-icon-s-order",
        "el-icon-s-platform",
        "el-icon-s-marketing",
        "el-icon-s-flag",
        "el-icon-s-data",
        "el-icon-s-check",
        "el-icon-data-line",
        "el-icon-data-board",
        "el-icon-pie-chart",
        "el-icon-data-analysis",
        "el-icon-collection-tag"
    ]
};
