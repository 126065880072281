<template>
    <div class="tags-container">
        <div class="tag_left" v-if="isShow">
            <el-button
                icon="el-icon-arrow-left"
                size="mini"
                title="前一个"
                @click="moveForward"
            ></el-button>
        </div>
        <div class="tag_center" :class="{ isWhole: !isShow }">
            <!-- tag盒子 -->
            <div class="tags-box" ref="tagBox">
                <div class="tags-list" ref="tagsList">
                    <div
                        ref="tagsPageOpened"
                        class="tag-item"
                        :class="{
                            'is-active': nowTagValue == item.value,
                            isOnly: !item.close
                        }"
                        v-for="(item, index) in tagList"
                        :key="index"
                        :name="item.value"
                        @click="openUrl(item)"
                        @contextmenu.prevent="openMenu(item, $event)"
                    >
                        <div class="tag-text">
                            {{ item.label || (item.meta && item.meta.title) }}
                        </div>
                        <i
                            v-if="item.close"
                            class="el-icon-close tag-close"
                            @click.stop="closeTag(item)"
                        ></i>
                    </div>
                </div>
            </div>
            <ul
                v-show="visible"
                :style="{ left: left + 'px', top: top + 'px' }"
                class="contextmenu"
            >
                <li @click="closeTag(selectedTag)">关闭</li>
                <li @click="closeOthersTags">关闭其他</li>
                <li @click="closeAllTags">关闭全部</li>
                <li
                    @click="refreshTag"
                    v-if="nowTagValue === selectedTag.value"
                >
                    刷新
                </li>
            </ul>
        </div>
        <div class="tag_right" v-if="isShow">
            <el-button
                icon="el-icon-arrow-right"
                size="mini"
                title="后一个"
                @click="backwardShift"
            ></el-button>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { setUrlPath, resolveUrlPath } from "@/utils/utils";

export default {
    name: "Tags",
    data() {
        return {
            tagBodyLeft: 0,
            isShow: false,
            screenWidth: document.body.clientWidth,

            visible: false,
            top: 0,
            left: 0,
            selectedTag: {}
        };
    },
    inject: ["reLoad"],
    watch: {
        screenWidth() {
            this.changeShow();
        },
        $route() {
            this.init();
        },
        tagBodyLeft(value) {
            this.$refs.tagsList.style.left = value + "px";
        },
        visible(value) {
            if (value) {
                document.body.addEventListener("click", this.closeMenu);
            } else {
                document.body.removeEventListener("click", this.closeMenu);
            }
        }
    },
    computed: {
        ...mapGetters(["tagWel", "tagList", "isCollapse", "tag"]),
        nowTagValue: function() {
            return setUrlPath(this.$route);
        }
    },
    methods: {
        changeShow() {
            if (this.$refs.tagsList && this.$refs.tagBox) {
                this.isShow =
                    this.$refs.tagsList.offsetWidth -
                        this.$refs.tagBox.offsetWidth >
                    0;
            } else {
                this.isShow = true;
            }
        },
        init() {
            let refsTag = this.$refs.tagsPageOpened;
            setTimeout(() => {
                refsTag.forEach(item => {
                    if (this.tag.value === item.attributes.name.value) {
                        let tag = item;
                        this.moveToView(tag);
                    }
                });
            }, 1);
        },
        moveToView(tag) {
            if (tag.offsetLeft < -this.tagBodyLeft) {
                // 标签在可视区域左侧
                this.tagBodyLeft = -tag.offsetLeft;
            } else if (
                tag.offsetLeft + 10 > -this.tagBodyLeft &&
                tag.offsetLeft + tag.offsetWidth <
                    -this.tagBodyLeft + this.$refs.tagBox.offsetWidth
            ) {
                // 标签在可视区域
                return;
            } else {
                // 标签在可视区域右侧
                this.tagBodyLeft = -(
                    tag.offsetLeft -
                    (this.$refs.tagBox.offsetWidth - 0 - tag.offsetWidth)
                );
            }
        },
        moveForward() {
            let boundarystart = 0;
            if (this.tagBodyLeft < boundarystart) {
                if (this.tagBodyLeft < 0 && this.tagBodyLeft + 100 > 0) {
                    this.tagBodyLeft = 0;
                } else {
                    this.tagBodyLeft = this.tagBodyLeft + 100;
                }
            }
        },
        backwardShift() {
            let boundaryend =
                this.$refs.tagsList.offsetWidth - this.$refs.tagBox.offsetWidth;
            if (this.tagBodyLeft > -boundaryend) {
                if (this.tagBodyLeft + boundaryend < 100) {
                    this.tagBodyLeft = -boundaryend;
                } else {
                    this.tagBodyLeft = this.tagBodyLeft - 100;
                }
            }
        },
        closeTag(item) {
            const key = this.eachTag(item);
            const itemWith = this.$refs.tagsPageOpened[key].offsetWidth;

            let tag;
            this.$store.commit("DEL_TAG", item);
            if (item.value == this.tag.value) {
                tag = this.tagList[key == 0 ? key : key - 1];
                this.openUrl(tag);
            }
            if (this.tagBodyLeft < -itemWith) {
                this.tagBodyLeft = this.tagBodyLeft + itemWith;
            } else {
                this.tagBodyLeft = 0;
            }
        },
        eachTag(tag) {
            for (var key in this.tagList) {
                if (this.tagList[key].value == tag.value) {
                    return key;
                }
            }
            return -1;
        },
        openUrl(item) {
            this.$router.push({
                path: resolveUrlPath(item.value, item.label),
                query: item.query
            });
        },
        openMenu(tag, e) {
            e.preventDefault();
            if (this.tagList.length == 1) {
                return;
            }

            this.visible = true;
            this.selectedTag = tag;
            this.left = e.clientX;
            this.top = e.clientY;
        },
        closeMenu() {
            this.visible = false;
        },
        closeOthersTags() {
            this.openUrl(this.selectedTag);
            this.$store.commit("DEL_TAG_OTHER");
        },
        closeAllTags() {
            this.$store.commit("DEL_ALL_TAG");
            this.$router.push({
                path: resolveUrlPath(this.tagWel.value),
                query: this.tagWel.query
            });
        },
        refreshTag() {
            this.reLoad();
        }
    },
    mounted() {
        window.onresize = () => {
            return (() => {
                this.screenWidth = document.body.clientWidth;
            })();
        };
        this.changeShow();
    },
    updated() {
        this.changeShow();
    }
};
</script>
<style lang="scss" scoped>
.tags-container {
    display: flex;
    justify-content: space-between;
    .tag_left,
    .tag_right {
        height: 100%;
        z-index: 1000;
        .el-button {
            height: 100%;
            width: 100%;
            padding: 1px;
        }
    }
    .tag_center {
        height: 100%;
        z-index: 999;
        .tags-box {
            position: relative;
            overflow: hidden;
            width: 100%;
            height: 100%;
            .tags-list {
                height: 100%;
                position: absolute;
                overflow: visible;
                white-space: nowrap;
                transition: left 0.3s ease;
                .tag-item {
                    display: inline-block;
                    box-sizing: border-box;
                    padding: 0 10px;
                    height: 100%;
                    cursor: pointer;
                    * {
                        display: inline-block;
                    }
                    .tag-close {
                        margin-left: 5px;
                    }
                }
            }
        }
    }
    .contextmenu {
        margin: 0;
        background: #fff;
        z-index: 999999;
        position: fixed;
        list-style-type: none;
        padding: 5px 0;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        color: #333;
        box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
        li {
            padding: 0 15px;
            height: 25px;
            line-height: 25px;
            cursor: pointer;
            &:hover {
                background: #eee;
                color: #3a8ee6;
            }
        }
        li:not(:last-child) {
            border-bottom: 1px solid #ebebed;
        }
    }
}
</style>
