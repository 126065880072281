import request from "@/router/axios";

export const loginByUsername = ({ username, password }) => {
    return request({
        url: "/api/admin/login",
        method: "post",
        params: {
            username,
            password
        }
    });
};

export const logout = () => {
    return request({
        url: "/api/admin/logout",
        method: "get"
    });
};
