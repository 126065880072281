import axios from "axios";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { Message } from "element-ui";
import store from "../store";
import router from "../router/router";

// NProgress Configuration
NProgress.configure({ showSpinner: false });

// 超时时间
axios.defaults.timeout = 30000;
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true;

// HTTPrequest拦截
axios.interceptors.request.use(
    config => {
        NProgress.start(); // start progress bar
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// HTTPresponse拦截
axios.interceptors.response.use(
    data => {
        NProgress.done();
        return data;
    },
    error => {
        NProgress.done();
        try {
            let status = error.response.status;
            // let msg = error.response.data.msg;

            if (status === 401) {
                Message({
                    message: "登录状态失效，即将跳转登录！",
                    type: "error",
                    duration: 3000,
                    onClose: () => {
                        store.dispatch("FedLogOut");
                        router.push({ path: "/login" });
                    }
                });
            }
        } catch (e) {
            console.error(e);
        }
        return Promise.reject(new Error(error));
    }
);

export default axios;
