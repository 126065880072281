<template>
    <div class="basic-container">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "basicContainer"
};
</script>

<style lang="scss">
.basic-container {
    padding: 5px;
    border-radius: 6px;
}
</style>
