<template>
    <div class="sidebar-container" :class="{ collapse: isCollapse }">
        <el-header class="avue-tabs">
            <Logo :isCollapse="isCollapse" />
        </el-header>
        <el-scrollbar class="el-menu-scrollbar">
            <el-menu
                class="el-menu-vertical"
                unique-opened
                :default-active="nowTagValue"
                mode="vertical"
                :collapse="isCollapse"
            >
                <SidebarItem :menu="siderMenu" :isCollapse="isCollapse" />
            </el-menu>
        </el-scrollbar>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { setUrlPath } from "@/utils/utils";
import Logo from "./logo";
import SidebarItem from "./sidebarItem";

export default {
    name: "Sidebar",
    components: {
        Logo,
        SidebarItem
    },
    props: {
        siderMenu: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        ...mapGetters(["isCollapse", "website"]),
        nowTagValue: function() {
            return setUrlPath(this.$route);
        }
    }
};
</script>
<style lang="scss" scoped>
$menuWidth: 200px;
$collapseMenuWidth: 60px;
.sidebar-container {
    transition: width 0.5s;
    &:not(.collapse) {
        width: $menuWidth;
    }
    &.collapse {
        width: $collapseMenuWidth;
    }
}
.sidebar-container {
    height: 100%;
    .el-menu-vertical {
        min-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        /deep/ .el-menu-item,
        /deep/ .el-submenu__title,
        /deep/ .el-menu-item-group .el-menu-item-group__title {
            overflow: hidden;
            white-space: nowrap;
        }
        &:not(.el-menu--collapse) {
            width: $menuWidth;
        }
    }
}
</style>
