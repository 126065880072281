import { getToken, removeToken } from "@/utils/auth";
import { setStore, getStore } from "@/utils/store";
import { loginByUsername, logout } from "@/api/admin/login";
import { getUserInfo, getMenu } from "@/api/admin/auth.js";

const user = {
    state: {
        token:
            getStore({
                name: "token"
            }) || "",
        rememberUser:
            getStore({
                name: "rememberUser"
            }) || {},
        isRememberPassword:
            getStore({
                name: "isRememberPassword"
            }) || false,
        userInfo:
            getStore({
                name: "userInfo"
            }) || {},
        permissions:
            getStore({
                name: "permissions"
            }) || {},
        roles:
            getStore({
                name: "roles"
            }) || [],
        menu:
            getStore({
                name: "menu"
            }) || [],
        menus:
            getStore({
                name: "menus"
            }) || [],
        laravel_session:
            getStore({
                name: "laravel_session"
            }) || "",
        xsrf_token:
            getStore({
                name: "xsrf_token"
            }) || ""
    },
    actions: {
        rememeberLoginInfo({ commit }, userInfo) {
            if (userInfo) {
                commit("SET_IS_REMEMBER_PASSWORD", true);
                commit("SET_REMEMBER_USER", userInfo);
            } else {
                commit("SET_IS_REMEMBER_PASSWORD", false);
                commit("SET_REMEMBER_USER", {});
            }
        },
        // 根据用户名登录
        LoginByUsername({ commit }, userInfo) {
            return new Promise((resolve, reject) => {
                loginByUsername(userInfo)
                    .then(response => {
                        if (response.data.status === 200) {
                            let token = getToken();
                            commit("SET_TOKEN", token);
                            commit("CLEAR_LOCK");
                            resolve(response);
                        } else {
                            reject(response.data.msg);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        GetUserInfo({ commit }) {
            return new Promise((resolve, reject) => {
                getUserInfo()
                    .then(response => {
                        commit("SET_USER_INFO", response.data.user);
                        commit("SET_PERMISSIONS", {});
                        commit("SET_ROLES", response.data.role);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        // 登出
        LogOut({ commit }) {
            return new Promise((resolve, reject) => {
                logout()
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        commit("SET_TOKEN", "");
                        commit("SET_USER_INFO", {});
                        commit("SET_PERMISSIONS", []);
                        commit("SET_ROLES", []);
                        commit("SET_MENU", []);
                        commit("SET_ORD_MENU", []);
                        commit("SET_LARAVEL_SESSION", "");
                        commit("SET_XSRF_TOKEN", "");
                        commit("DEL_ALL_TAG");
                        removeToken();
                    });
            });
        },
        // 注销session
        FedLogOut({ commit }) {
            return new Promise(resolve => {
                commit("SET_TOKEN", "");
                commit("SET_USER_INFO", {});
                commit("SET_PERMISSIONS", []);
                commit("SET_ROLES", []);
                commit("SET_MENU", []);
                commit("SET_ORD_MENU", []);
                commit("SET_LARAVEL_SESSION", "");
                commit("SET_XSRF_TOKEN", "");
                commit("DEL_ALL_TAG");
                removeToken();
                resolve();
            });
        },
        // 获取系统菜单
        GetMenu({ commit }) {
            return new Promise(resolve => {
                getMenu().then(res => {
                    let data = res.data;
                    commit("SET_ORD_MENU", data);
                    if (Array.isArray(data)) {
                        let menus = data.map(menu => {
                            return {
                                id: menu.id,
                                name: menu.menu,
                                label: menu.menu_name,
                                path: menu.path,
                                icons: menu.icon,
                                component: menu.component,
                                parentId: menu.parent_id,
                                type: menu.type,
                                sort: menu.sort,
                                meta: {
                                    keepAlive: true,
                                    title: menu.menu_name
                                }
                            };
                        });
                        menus.sort(function(a, b) {
                            return a.sort - b.sort;
                        });
                        menus.forEach(item => {
                            if (item.parentId != -1) {
                                for (let menu of menus) {
                                    if (menu.id === item.parentId) {
                                        if (item.type == 0) {
                                            if (!Array.isArray(menu.children)) {
                                                menu.children = [];
                                            }
                                            menu.children.push(item);
                                        } else if (item.type == 1) {
                                            if (
                                                !Array.isArray(
                                                    menu.meta.permissions
                                                )
                                            ) {
                                                menu.meta.permissions = [];
                                            }
                                            menu.meta.permissions.push(
                                                item.name
                                            );
                                        }
                                        break;
                                    }
                                }
                            }
                        });
                        commit(
                            "SET_MENU",
                            menus.filter(item => {
                                return item.parentId == -1 && item.type == 0;
                            })
                        );
                    }
                    resolve();
                });
            });
        }
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token;
            setStore({
                name: "token",
                content: state.token,
                type: "session"
            });
        },
        SET_REMEMBER_USER: (state, rememberUser) => {
            state.rememberUser = rememberUser;
            setStore({
                name: "rememberUser",
                content: state.rememberUser
            });
        },
        SET_IS_REMEMBER_PASSWORD: (state, isRememberPassword) => {
            state.isRememberPassword = isRememberPassword;
            setStore({
                name: "isRememberPassword",
                content: state.isRememberPassword
            });
        },
        SET_USER_INFO: (state, userInfo) => {
            state.userInfo = userInfo;
            setStore({
                name: "userInfo",
                content: state.userInfo,
                type: "session"
            });
        },
        SET_PERMISSIONS: (state, permissions) => {
            const list = {};
            for (let i = 0; i < permissions.length; i++) {
                list[permissions[i]] = true;
            }
            state.permissions = list;
            setStore({
                name: "permissions",
                content: state.permissions,
                type: "session"
            });
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles;
            setStore({
                name: "roles",
                content: state.roles,
                type: "session"
            });
        },
        SET_MENU: (state, menu) => {
            state.menu = menu;
            setStore({
                name: "menu",
                content: state.menu,
                type: "session"
            });
        },
        SET_ORD_MENU: (state, menus) => {
            state.menus = menus;
            setStore({
                name: "menus",
                content: state.menus,
                type: "session"
            });
        },
        SET_LARAVEL_SESSION: (state, laravel_session) => {
            state.laravel_session = laravel_session;
            setStore({
                name: "laravel_session",
                content: state.laravel_session,
                type: "session"
            });
        },
        SET_XSRF_TOKEN: (state, xsrf_token) => {
            state.xsrf_token = xsrf_token;
            setStore({
                name: "xsrf_token",
                content: state.xsrf_token,
                type: "session"
            });
        }
    }
};
export default user;
