<template>
    <div class="top">
        <h1 class="top-title">
            <div class="top_icons">
                <span class="logo_title is-bold" v-if="menuType === 'top'"
                    >{{ website.title }}
                </span>
                <div
                    v-else
                    class="top_icon"
                    :class="[{ 'tag-collapse_right': isCollapse }]"
                >
                    <i
                        class="iconfont icon-cp-double-arrow-left tag-collapse"
                        @click="showCollapse"
                    ></i>
                </div>
            </div>
        </h1>
        <el-dropdown class="user-info" @command="avatarCommand">
            <div class="user-info-avatar">
                <el-avatar :src="avatar">{{ userInfo.username }}</el-avatar>
                <span class="username">{{ userInfo.username }}</span>
            </div>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="account">账号中心</el-dropdown-item>
                <el-dropdown-item command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    name: "Top",
    props: {
        topMenu: {
            type: Array,
            default: () => []
        },
        menuType: {
            type: String,
            default: "aside"
        },
        themeName: {
            type: String,
            default: "theme-one"
        }
    },
    computed: {
        ...mapGetters(["isFullScren", "isCollapse", "website", "userInfo"]),
        avatar() {
            return this.userInfo.avatar && this.userInfo.avatar.avatar;
        }
    },
    methods: {
        showCollapse() {
            this.$store.commit("SET_COLLAPSE");
        },
        avatarCommand(command) {
            switch (command) {
                case "account":
                    this.$router.push({
                        name: "账号中心"
                    });
                    break;
                case "logout":
                    this.$store.dispatch("LogOut").finally(() => {
                        this.$message({
                            message: "退出成功！即将返回登录页",
                            type: "success"
                        });
                        setTimeout(() => {
                            this.$message.closeAll();
                            this.$router.push({
                                name: "登录"
                            });
                        }, 1000);
                    });
                    break;
                default:
                    break;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
$topHeight: 60px;

$titleWidth: 200px;
$titleSize: 24px;
$titleColor: #409eff;

$iconCollapseColor: #555;

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .top-title {
        height: 100%;
        margin: 0;
        .top_icons {
            float: left;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .logo_title {
                height: 100%;
                padding: 0 10px 0 0px;
                color: $titleColor;
                font-size: $titleSize;
                line-height: $topHeight;
                &.is-bold {
                    font-weight: 700;
                }
            }
            .top_icon {
                transition: 0.5s;
                &.tag-collapse_right {
                    transform: rotateY(180deg);
                }
                i {
                    font-size: $titleSize !important;
                }
                .tag-collapse {
                    color: $iconCollapseColor;
                }
            }
        }
    }
    .user-info {
        .user-info-avatar {
            display: flex;
            justify-content: space-around;
            align-items: center;
            > * + * {
                margin-left: 5px;
            }
            .username {
                font-size: 14px;
            }
        }
    }
}
</style>
