import { validatenull } from "./validate";
import Layout from "@/page/index/index";
import { resetRouter } from "@/router/router";

export const initRoutes = (router, menu) => {
    resetRouter();
    if (menu.length === 0) {
        return;
    }
    router.addRoutes(formatRoutes(menu));
};

export const formatRoutes = aMenu => {
    const aRouter = [];
    const defaultRouter = [];
    aMenu.forEach(oMenu => {
        const {
            path,
            component,
            name,
            icons,
            children,
            meta,
            parentId
        } = oMenu;
        if (!validatenull(component)) {
            const oRouter = {
                path: path ? path : "",
                component(resolve) {
                    require([`../${component}.vue`], resolve);
                },
                name: name,
                icon: icons,
                children: validatenull(children) ? [] : formatRoutes(children),
                meta: meta
            };
            if (
                parentId == -1 &&
                (!children || children.length < 1) &&
                component != "page/index/index"
            ) {
                defaultRouter.push(oRouter);
            } else {
                aRouter.push(oRouter);
            }
        }
    });
    if (defaultRouter.length > 0) {
        aRouter.push({
            path: "/wel",
            component: Layout,
            redirect: "/wel/index",
            children: defaultRouter
        });
    }
    return aRouter;
};
