const getters = {
    website: state => state.common.website,
    theme: state => state.common.theme,
    themeName: state => state.common.themeName,
    cacheControl: state => state.common.cacheControl,
    isCollapse: state => state.common.isCollapse,
    isLock: state => state.common.isLock,
    isFullScren: state => state.common.isFullScren,
    isLoading: state => state.common.isLoading,
    isTags: state => state.common.isTags,
    menuType: state => state.common.menuType,
    lockPasswd: state => state.common.lockPasswd,
    tag: state => state.tags.tag,
    tagList: state => state.tags.tagList,
    tagCurrent: state => state.tags.tagCurrent,
    tagWel: state => state.tags.tagWel,
    token: state => state.user.token,
    rememberUser: state => state.user.rememberUser,
    isRememberPassword: state => state.user.isRememberPassword,
    roles: state => state.user.roles,
    userInfo: state => state.user.userInfo,
    permissions: state => state.user.permissions,
    menu: state => state.user.menu,
    menus: state => state.user.menus,
    errLog: state => state.errLog.errLog,
    sendCode: state => state.sendCode
};
export default getters;
