import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { formatRoutes } from "@/utils/router";
import baseRouter from "./_router";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

let createRouter = () =>
    new VueRouter({
        strict: process.env.NODE_ENV !== "production",
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition;
            } else {
                if (from.meta.keepAlive) {
                    from.meta.savedPosition = document.body.scrollTop;
                }
                return {
                    x: 0,
                    y: to.meta.savedPosition || 0
                };
            }
        },
        routes: [].concat(...formatRoutes(store.state.user.menu), baseRouter)
    });

const router = createRouter();

export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher;
}

export default router;
