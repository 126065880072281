<template>
    <div class="breadcrumb-container">
        breadcrumb
    </div>
</template>
<script>
export default {
    name: "Breadcrumb"
};
</script>
<style lang="scss" scoped></style>
