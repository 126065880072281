import Vue from "vue";
import VueAxios from "vue-axios";
import VueMoment from "vue-moment";
import VueWechatTitle from "vue-wechat-title";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import * as urls from "@/config/env";
import * as filters from "./filters";

import "./permission";
import axios from "./router/axios";
import router from "./router/router";
import store from "./store";
import base from "./utils/base";
import "./errorLog";

import App from "./App.vue";
import basicContainer from "./components/basic-container/main";

import "babel-polyfill";
import "./styles/common.scss";

Object.keys(urls).forEach(key => {
    Vue.prototype[key] = urls[key];
});
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
});

Vue.config.productionTip = false;

Vue.use(VueWechatTitle);
Vue.use(ElementUI);
Vue.use(VueMoment);
Vue.use(VueAxios, axios);
Vue.use(base);

Vue.component("basicContainer", basicContainer);

let createApp = function() {
    const app = new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount("#app");
    return {
        app,
        router,
        store
    };
};
export default createApp();
