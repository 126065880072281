import Vue from "vue";
import Vuex from "vuex";

import getters from "./getters";

import common from "./modules/common";
import user from "./modules/user";
import errLog from "./modules/errLog";
import tags from "./modules/tags";
import sendCode from "./modules/sendCode";

Vue.use(Vuex);
const store = new Vuex.Store({
    modules: {
        common,
        user,
        tags,
        errLog,
        sendCode
    },
    getters
});

export default store;
