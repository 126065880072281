import { baseUrl } from "@/config/env";

/**
 * 设置浏览器头部标题
 */
export const setTitle = function(title) {
    title = title ? `${title}` : "Zmr服务开发框架";
    window.document.title = title;
};

/**
 * 总体路由设置器
 */
export const setUrlPath = $route => {
    let value = "";
    if ($route.query.src) {
        value = $route.query.src;
    } else {
        value = $route.path;
    }
    return value;
};
export const resolveUrlPath = (url, name) => {
    let reqUrl = url;
    if (url.indexOf("#") !== -1 && url.indexOf("http") === -1) {
        const port = reqUrl.substr(reqUrl.indexOf(":"));
        reqUrl = `/myiframe/urlPath?src=${baseUrl}${port}${reqUrl.replace("#", "").replace(port, "")}&name=${name}`;
    } else if (url.indexOf("http") !== -1) {
        reqUrl = `/myiframe/urlPath?src=${reqUrl}&name=${name}`;
    } else {
        reqUrl = `${reqUrl}`;
    }
    return reqUrl;
};
