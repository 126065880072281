import { setStore, getStore } from "@/utils/store";

const sendCode = {
    state: {
        verityCodeCountDown: -1,
        authCode:
            getStore({
                name: "authCode"
            }) || ""
    },
    mutations: {
        SET_VERITY_CODE_COUNT_DOWN: (state, count) => {
            if (count) {
                state.verityCodeCountDown = count;
            } else {
                state.verityCodeCountDown--;
            }
        },
        SET_AUTH_CODE: (state, authCode) => {
            state.authCode = authCode;
            setStore({
                name: "authCode",
                content: state.authCode,
                type: "session"
            });
        }
    },
    actions: {
        saveAuthCode({ commit }, authCode) {
            commit("SET_AUTH_CODE", authCode);
            setTimeout(() => {
                commit("SET_AUTH_CODE", "");
            }, 300000);
        },
        doCountDwonVerityCode({ commit, state }, time = 60) {
            commit("SET_VERITY_CODE_COUNT_DOWN", time);
            let timer = setInterval(() => {
                if (state.verityCodeCountDown < 0) {
                    clearInterval(timer);
                    return;
                }
                commit("SET_VERITY_CODE_COUNT_DOWN");
            }, 1000);
        }
    }
};

export default sendCode;
