import request from "@/router/axios";

export const getUserInfo = () => {
    return request({
        url: "/api/admin/auth/info",
        method: "get"
    });
};
export const getMenu = () => {
    return request({
        url: "/api/admin/auth/menu",
        method: "get"
    });
};
