const _import = require("./_import");
import Layout from "@/page/index/index";
export default [
    {
        path: "/",
        name: "主页",
        meta: {
            title: "主页"
        },
        redirect: "/wel"
    },
    {
        path: "/wel",
        component: Layout,
        redirect: "/wel/index",
        children: [
            {
                path: "index",
                name: "首页",
                meta: {
                    title: "首页"
                },
                component: _import("index", "views/wel")
            },
            {
                path: "/MyAccount",
                name: "账号中心",
                meta: {
                    title: "账号中心"
                },
                component: _import("index", "views/AccountCenter/MyAccount")
            }
        ]
    },
    {
        path: "/login",
        name: "登录",
        meta: {
            title: "登录"
        },
        component: _import("login/index")
    },
    {
        path: "/reset",
        component: _import("reset/index")
    },
    {
        path: "/lock",
        name: "锁屏页",
        component: _import("lock/index")
    },
    {
        path: "/404",
        name: "404",
        component: _import("error-page/404", "components")
    },
    {
        path: "/500",
        name: "500",
        component: _import("error-page/500", "components")
    },
    {
        path: "*",
        redirect: "/404",
        hidden: true
    }
];
